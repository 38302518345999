<template>
  <footer class="main-footer">
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
</style>