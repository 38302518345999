import http from '@/utils/http';

export default {
  async login(data) {
    return await http.post('/api/usuario/login', data);
  },
  async getCurrent() {
      return await http.get('/api/usuario/current');
  },
  async getById(id) {
    return await http.get(`/api/usuario/get/${id}`);
  },
  async insert(data) {
    return await http.post('/api/usuario/insert', data);
  },
  async update(data) {
    return await http.post('/api/usuario/update', data);
  },
  async delete(data) {
    return await http.post('/api/usuario/delete', data);
  }
};