<template>
    <div class="input-group">
        <div class="input-group-prepend">
            <span class="input-group-text">
                <i class="far fa-calendar-alt"></i>
            </span>
        </div>
        <input type="text" class="form-control float-right calendario" :placeholder="this.placeholder || 'dd/mm/aaaa'"/>
    </div>
</template>

<script>
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';
import 'jquery.inputmask/dist/jquery.inputmask.bundle';

export default {
    props: ['value', 'placeholder'],
    mounted: function () {
        const vm = this;

        $(this.$el).find("input")
        .val(this.value)
        .datepicker({
            clearBtn: true,
            todayBtn: 'linked',
            todayHighlight: true,
            language: 'pt-BR',
            format: "dd/mm/yyyy",
            autoclose: true,
            changeYear: true
        }).on("changeDate", function() {
            vm.$emit('input', this.value);
        });

        $(this.$el).find("input").inputmask('dd/mm/yyyy',{ 'placeholder': 'dd/mm/aaaa' });
    },
    watch: {
        value: function (value) {
            $(this.$el).find("input").val(value).trigger('change');
        }
    },
    destroyed: function () {
        $(this.$el).find("input").datepicker('destroy');
        $(this.$el).find("input").inputmask('remove');
    }
};
</script>