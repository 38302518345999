<template>
    <select class="form-control">
        <slot></slot>
    </select>
</template>

<script>
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.css';
import http from '@/utils/http';
import store from '@/store';

export default {
    props: ['options', 'value', 'ajax'],
    mounted: function () {
        const vm = this;

        let config = {
            allowClear: true,
            data: this.options,
            width: '100%',
            minimumResultsForSearch: 10,
            ajax: null,
            initSelection: null
        };

        if(this.ajax)
        {
            $.extend(config, { 
                ajax: {
                    url: process.env.VUE_APP_BASE_API + this.ajax,
                    dataType: "json",
                    contentType:"application/json; charset=utf-8",
                    type: "POST",
                    headers: {
                        "Authorization": 'Bearer ' + store.state.token
                    },
                    data: function (params) {
                        return JSON.stringify({
                            q: params.term,
                            page: params.page || 1,
                            value: vm.value
                        })
                    }
                }
            });
        }

        $(this.$el).data('prompt-position', 'topRight:' + $(this.$el).width());
        $(this.$el)
            .select2(config)
            .val(this.value)
            .trigger('change')
            .on('change', function (e) {
                vm.$emit('input', this.value);
                vm.$emit('change', e);
            });
    },
    watch: {
        value: function (value) {
            if (this.ajax && value !== '' && !$(this.$el).find("option[value='" + value + "']").length) {
                http.post(this.ajax, { value: value })
                .then(response => {
                    if(response.data.results)
                    {
                        $(this.$el).append(new Option(response.data.results[0].text, response.data.results[0].id, true, true));
                    }else{
                        $(this.$el).append(new Option(value, value, true, true));
                    }
                    $(this.$el).val(value).trigger('change');
                });
            }else{
                $(this.$el).val(value).trigger('change');
            }
        },
        options: function (options) {
            $(this.$el).empty().select2({ data: options });
        }
    },
    destroyed: function () {
        $(this.$el).select2('destroy');
    }
};
</script>