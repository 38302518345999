<template>
  <table class="table table-bordered table-striped">
    <slot></slot>
  </table>
</template>

<script>
import 'datatables.net/js/jquery.dataTables.min.js';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-responsive/js/dataTables.responsive.min.js';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4.min.js';
import 'datatables.net-buttons/js/dataTables.buttons.min.js';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js';
import 'datatables.net-plugins/api/fnSetFilteringDelay.js';
import 'jszip/dist/jszip.min.js';
import pdfMake from 'pdfmake/build/pdfmake.min.js';
import vfsFonts from 'pdfmake/build/vfs_fonts.js';
import 'datatables.net-buttons/js/buttons.html5.min.js';
import 'datatables.net-buttons/js/buttons.print.min.js';
import 'datatables.net-buttons/js/buttons.colVis.min.js';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
import store from '@/store';

export default {
  props: ['options'],
  mounted: function() {
    const {vfs} = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;

    $.extend(true, $.fn.dataTable.defaults, {
        "processing": false,
        "serverSide": true,
        "autoWidth": false,
        "stateSave": true,
        "stateSaveCallback": function(settings, data) {
            localStorage.setItem(location, JSON.stringify(data));
        },
        "stateLoadCallback": function() {
            return JSON.parse(localStorage.getItem(location));
        },
        "pageLength": 25,
        "lengthMenu": [[10,25,50,100, 100000], [10,25,50, 100, "Todos"]],
        "order": [[0, "asc"]],
        "responsive": true,
        "lengthChange": true,
        "ajax": {
          "type": "POST",
          "contentType": "application/json; charset=utf-8",
          "dataType": "json",
            "headers": {
                "Authorization": 'Bearer ' + store.state.token
            }
        },
        dom: 'Bfrtip',
        buttons: [
            {
                extend: 'pageLength',
                titleAttr: 'Registros por página',
                text: '<i class="fas fa-list-ol"></i>'
            },
            {
                extend: 'colvis',
                titleAttr: 'Visibilidade',
                columns: 'th:not(:empty):not(.text-center)',
                text: '<i class="far fa-eye"></i>',
            },
            {
                extend: 'csv',
                titleAttr: 'Exportar para o Excel',
                text: '<i class="far fa-file-excel"></i>',
                fieldSeparator: ";",
                charset: 'utf-8',
                bom: true,
                title: (this.options.title) ? this.options.title : null,
                exportOptions: {
                    columns: 'th:not(:empty):not(.text-center)'
                }
            },
            {
                extend: 'pdf',
                titleAttr: 'Exportar para PDF',
                text: '<i class="fas fa-file-pdf"></i>',
                title: (this.options.title) ? this.options.title : null,
                exportOptions: {
                    columns: 'th:not(:empty):not(.text-center)'
                }
            },
            {
                extend: 'print',
                titleAttr: 'Imprimir',
                text: '<i class="fas fa-print"></i>',
                title: (this.options.title) ? this.options.title : null,
                exportOptions: {
                    columns: 'th:not(:empty):not(.text-center)'
                }
            }
        ],
        language: {
            "sEmptyTable": "Nenhum registro encontrado",
            "sInfo": "Exibindo de _START_ até _END_ de um total de _TOTAL_ registros",
            "sInfoEmpty": "Exibindo de 0 até 0 de um total de 0 registros",
            "sInfoFiltered": "(filtrado de um total de _MAX_ registros)",
            "sInfoPostFix": "",
            "sInfoThousands": "",
            "sLengthMenu": "Exibir _MENU_ registros por página",
            "sLoadingRecords": "<i class='fa fa-spinner fa-pulse fa-fw'></i> carregando...",
            "sProcessing": "<i class='fa fa-spinner fa-pulse fa-fw'></i> carregando...",
            "sZeroRecords": "Nenhum registro encontrado",
            "sSearch": "Busca",
            "oPaginate": {
                "sNext": "<i class='fa fa-angle-right'></i>",
                "sPrevious": "<i class='fa fa-angle-left'></i>",
                "sFirst": "Primeira",
                "sLast": "Última"
            },
            "oAria": {
                "sSortAscending": ": Ordenar colunas de forma ascendente",
                "sSortDescending": ": Ordenar colunas de forma descendente"
            },
            "select": {
                "rows": {
                    "_": "Selecionado %d linhas",
                    "0": "Nenhuma linha selecionada",
                    "1": "Selecionado 1 linha"
                }
            }
        }
    });

    if(this.options.ajax.url)
    {
        this.options.ajax.url = process.env.VUE_APP_BASE_API + this.options.ajax.url;
    }

    $(this.$el).DataTable(this.options);
    $(this.$el).dataTable().fnSetFilteringDelay(600);
    $(this.$el).on('processing.dt', function (e, settings, processing) {
        if (processing) {
            $('.overlay').show();
        } else {
            $('.overlay').hide();
        }
    });
  },
  destroyed: function () {
    $(this.$el).DataTable().destroy();
  }
};
</script>