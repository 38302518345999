<template>
    <div>
        <img v-if="this.value" v-bind:src="this.value" class="img-thumbnail" id="prv_foto" style="width: 100%; height: 100%; max-width: 100%; max-height: 100%;" />
        <input type="file" name="foto" class="filestyle" accept="image/*" @change="previewFiles" />
    </div>
</template>

<script>
import "bootstrap-filestyle/src/bootstrap-filestyle";
 
export default {
    props: ["value"],
    mounted: function () {
        $(this.$el).find("input").filestyle({
            buttonName: "btn-primary",
            buttonText: "Upload",
            iconName: "fas fa-folder-open",
            input: true,
            placeholder: "Selecione o Arquivo."
        });
    },
    methods: {
        previewFiles(event) {
            const vm = this;
            var files = event.target.files;
            var file = files[0];

            if (files && file) {
                var reader = new FileReader();

                reader.onload = function(readerEvt) {
                    vm.$emit("input", readerEvt.target.result);
                };
                reader.readAsDataURL(file);
            }
        }
    },
    destroyed: function () {
         $(this.$el).find("input").filestyle("destroy");
    }
};
</script>