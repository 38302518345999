import 'expose-loader?$!expose-loader?jQuery!jquery';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

Vue.config.productionTip = false;

import "overlayscrollbars/css/OverlayScrollbars.min.css";
import "@/assets/adminlte/css/adminlte.css";
import "@/assets/fontawesome-free/css/all.css";
//import "@/assets/font/font.css";
import "@/assets/custom.css";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "overlayscrollbars/js/jquery.overlayScrollbars.min.js";
import "@/assets/adminlte/js/adminlte.js";
import "@/assets/custom.js";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');