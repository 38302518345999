import axios from 'axios';
import store from '@/store';

const instance = axios.create({
  timeout: 300000,
  baseURL: process.env.VUE_APP_BASE_API
});

instance.interceptors.request.use(
  config => {
    config.headers.Authorization = 'Bearer ' + store.state.token;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (!error.response ||error.response.status == 401) {
      store.dispatch("logOut");
    } else {
        return Promise.reject(error);
    }
  }
);

export default instance;