import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || "",
    email: localStorage.getItem("email") || ""
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    SET_EMAIL(state, email) {
      state.email = email;
      localStorage.setItem('email', email);
    },
    LOG_IN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    LOG_OUT(state) {
      state.token = null;
      localStorage.removeItem('token');
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit('SET_TOKEN', token);
    },
    setEmail({ commit }, email) {
      commit('SET_EMAIL', email);
    },
    logOut({ commit }) {
      commit('LOG_OUT');
    }
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getEmail(state) {
      return state.email;
    }
  },
  modules: {
  }
});