import 'jquery-validation-engine/js/jquery.validationEngine.js';
import 'jquery-validation-engine/js/languages/jquery.validationEngine-pt_BR.js';
import 'jquery-validation-engine/js/contrib/other-validations.js';
import 'jquery-validation-engine/css/validationEngine.jquery.css';

export default {
    validate: function(id){
        return $(id).validationEngine({
            promptPosition : "topRight",
            focusFirstField : true,
            maxErrorsPerField: true,
            autoHidePrompt: true,
            autoHideDelay: 10000,
            validationEventTrigger: 'change',
            onFieldFailure: function (input) {
                $(input).parents('.form-group').addClass('has-error');
            },
            onFieldSuccess: function (input) {
                $(input).parents('.form-group').removeClass('has-error');
            }
        });
    },
    isValid: function(id){
        return $(id).validationEngine('validate');
    }
}