<template>
  <div class="login-box">
    <!-- /.login-logo -->
    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        <h1><b>Admin</b>LTE</h1>
      </div>
      <div class="card-body">
        <p class="login-box-msg">Informe seu E-mail e Senha de Acesso.</p>
        <form id="form1">
          <div class="input-group mb-3">
            <input type="email" v-model="loginData.email" class="form-control validate[required, custom[email]]" placeholder="E-mail"/>
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" v-model="loginData.senha" class="form-control validate[required, minSize[4]]" placeholder="Senha"/>
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <div class="icheck-primary">
                <input type="checkbox" id="remember" v-model="loginData.lembrar">
                <label for="remember">
                  Lembrar
                </label>
              </div>
            </div>
            <!-- /.col -->
            <div class="col-4">
              <button type="button" v-on:click="login" class="btn btn-primary btn-social float-right"><i class="fas fa-sign-in-alt"></i> Acessar</button>
            </div>
            <!-- /.col -->
          </div>
        </form>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </div>
  <!-- /.login-box -->
</template>

<script>
import usuario from "@/controllers/usuario.controller";
import validator from "@/utils/validator";
import toastr from "@/utils/toastr";
import 'icheck-bootstrap/icheck-bootstrap.min.css';

export default {
  name: "Login",
  mounted(){
    validator.validate("#form1");
  },
  data: function() {
    return {
      loginData: {
        email: this.$store.state.email || null,
        senha: null,
        lembrar: (this.$store.state.email)
      }
    };
  },
  methods: {
    login: function() {
      if (!validator.isValid("#form1"))
          return;

      usuario.login(this.loginData)
        .then(response => {
          if(!response.data.success)
          {
            toastr.error(response.data.error);
            return;
          }
          
          if(this.loginData.lembrar)
            this.$store.dispatch('setEmail', this.loginData.email);
          else
            this.$store.dispatch('setEmail', "");

          this.$store.dispatch('setToken', response.data.result.accessToken)
            .then(() => {
              location.href = '/dashboard';
            });
        })
        .catch(e => {
          toastr.error(e);
        });
    }
  },
  created: function() {
    document.body.classList.add("login-page");
  },
  destroyed: function() {
    document.body.classList.remove("login-page");
  }
};
</script>

<style scoped>

</style>
