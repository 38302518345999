<template>
  <div class="wrapper">
    <!-- Preloader -->
    <div class="preloader flex-column justify-content-center align-items-center">
      <img class="animation__shake" src="https://adminlte.io/themes/v3/dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
    </div>

    <Header/>
    <SidebarMenu/>

    <div class="content-wrapper">
      <router-view></router-view>
    </div>

    <Footer/>
  </div>
</template>

<script>
import { Vue } from "vue-property-decorator";
import Header from "@/components/main/Header";
import SidebarMenu from "@/components/main/SidebarMenu";
import Footer from "@/components/main/Footer";

import ClientTable from "@/components/ClientTable";
import SelectEditor from "@/components/editors/SelectEditor";
import ImageEditor from "@/components/editors/ImageEditor";
import DateEditor from "@/components/editors/DateEditor";

import validator from "@/utils/validator";
import toastr from "@/utils/toastr";
import dialogs from "@/utils/dialogs";

Vue.component("client-table", ClientTable);
Vue.component("select-editor", SelectEditor);
Vue.component("image-editor", ImageEditor);
Vue.component("date-editor", DateEditor);

Vue.prototype.$validator = validator;
Vue.prototype.$toastr = toastr;
Vue.prototype.$dialogs = dialogs;

export default {
  name: "Main",
  components: {
    Header,
    SidebarMenu,
    Footer
  }
};
</script>

<style scoped>
</style>